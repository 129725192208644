.mainContent {
    width: 50%;
    height: 75%;
    background: #fff;
    margin: auto;
    margin-top: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    border-radius: 30px;
    transition: all 500ms ease;
}
.data {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.dataItem {
    width: 80%;
    height: 8%;
    border-bottom: 1px solid #848484;
    color:#848484;
    display: flex;
    align-items: center;
}

.info {
    display: block;
    font-size: 20px;
    font-weight:400;
    margin: 20px;
}
.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
}
.formContent{
    width: 70%;
    margin: 30px;
    
}
.label {
    margin: auto 30px;
}

.input {
    width: 80%;
    height: 50px;
    margin: 10px;
    border: 1px solid #707070;
}

.btnContent {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
}
.checkboxContainer {
    display: flex;
    flex-direction:row;
    justify-content: center;
    margin-bottom: 2px;
}

.btn {
    width: 40%;
    height: 50px;
    color: #fff;
    background-color: #107ACC;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
}
.btn:hover {
    background-color: #103FCC;
}
.btn:active {
    transform: scale(0.95);
}

@media (min-width: 768px) and (max-width: 1024px) {
    .mainContent {
        width: 80%;
    }
    .form {
        width: 100%;
    }
    .formContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btnContent {
        width: 100%;
    }
}

/* Móviles Medianos */
@media (min-width: 481px) and (max-width: 767px) {
    .mainContent {
        width: 90%;
        margin-bottom: 120px;
    }
    .form {
        width: 100%;
        margin: 25px auto;
    }
    .formContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btnContent {
        width: 70%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .btn{
        width: 80%;
        margin: 10px;
    }
}


/* Móviles Pequeños */
@media (max-width: 480px) {
    .mainContent {
        width: 90%;
        margin-bottom: 120px;
    }
    .form {
        width: 100%;
        margin: 25px auto;
    }
    .formContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btnContent {
        width: 70%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .btn{
        width: 100%;
        margin: 10px;
    }
}
