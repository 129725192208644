
section {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

section .dots span {
    position: absolute;
    height: 5px;
    width: 5px;
    background: #fff;
    border-radius: 50%;
    transform: rotate(calc(var(--i)*(360deg/10))) translateY(15px);
    animation: loader 1s linear infinite;
    animation-delay: calc(var(--i)*0.1s);
    opacity: 1;
}

@keyframes loader {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}