.mainContent {
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;
    display: flex;
    
    
}

@media (max-width: 767px) {
    .mainContent {
        min-height: 1000px;
    }
}