.nav{
    width: 100%;
    height: 75px;
    background: #107ACC;
    display: flex;
    justify-content: space-between;
    position: absolute;
}
.nav img{
    height: 70px;
    margin: auto 15px;
}

.nav h1{
    margin: auto 150px;
    font-size: 35px;
    color: white;
}
@media (min-width: 481px) and (max-width: 767px) {
    .nav img{
        height: 70px;
        margin: auto 15px;
    }
    .nav h1 {
        font-size: 30px;
        margin: auto 100px;
    }
}


/* Móviles Pequeños */
@media (max-width: 480px) {
    .nav img{
        height: 60px;
        margin: 5px 15px;
    }
    .nav h1 {
        font-size: 25px;
        margin: auto 20px;
    }
}