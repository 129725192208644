.errorContent {
    width: 70%;
    height: 45px;
    margin: 5px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: crimson;
    border: 1px solid red;
    text-align: center;
    color: #fff;

}