.mainContent {
    width: 60%;
    height: 70%;
    background: #fff;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    border-radius: 30px;
}

.data {
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.data_payments {
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    overflow: scroll;
}
.dataItem {
    width: 80%;
    height: 8%;
    border-bottom: 1px solid #848484;
    color:#848484;
    display: flex;
    align-items: center;
}

.info {
    display: block;
    font-size: 20px;
    font-weight:400;
    margin: 20px;
}

.label {
    font-size: 13px;
}

.aside{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnContent {
    width: 100%;
    height: 60%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


.btn {
    width: 70%;
    height: 70px;
    margin: 10px 0;
    background: #107ACC;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #fff;
    font-size: 16px;
    
}

.btn:hover {
    background: #103FCC;
}

.linksContent {
    display: flex;
    height: 40%;
    width: 70%;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.links {
    text-align: center;
    margin: 10px 0; 
    color: #107ACC;
    text-decoration: none;
}

/* Tabletas */
@media (min-width: 768px) and (max-width: 1024px) {
    .mainContent {
        width: 85%;
    }
}

/* Móviles Medianos */
@media (min-width: 481px) and (max-width: 767px) {
    .mainContent {
        width: 90%;
        height: 90%;
        margin-top: 100px;
        margin-bottom: 60px;
        flex-direction: column;
    }
    .data {
        width: 90%;
        margin-top: 20px;
    }
    .info {
        font-size: 20px;
        margin: 15px;
    }
    .label {
        font-size: 13px;
    }
    .aside {
        flex-direction: row;
        width: 80%;
    }
    .btnContent {
        flex-direction: row-reverse;
        justify-content: space-around;
    }
    .btn {
        width: 30%;
        font-size: 13px;
        text-align: center;
    }
    .linksContent {
        display: none;
    }
    
}


/* Móviles Pequeños */
@media (max-width: 480px) {
    .mainContent {
        width: 90%;
        height: 90%;
        margin-top: 100px;
        margin-bottom: 60px;
        flex-direction: column;
    }
    .data {
        width: 90%;
        margin-top: 20px;
    }
    .info {
        font-size: 20px;
        margin: 15px;
    }
    .label {
        font-size: 13px;
    }
    .aside {
        flex-direction: row;
        width: 80%;
    }
    .btnContent {
        flex-direction: row-reverse;
        justify-content: space-around;
    }
    .btn {
        width: 30%;
        font-size: 13px;
        text-align: center;
    }
    .linksContent {
        display: none;
    }
}
