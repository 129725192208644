.mainContent {
    width: 30%;
    height: 65%;
    background: #fff;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    border-radius: 5%;
    transition: all 500ms ease;
}

.label {
    margin: auto 30px;
}

.input {
    width: 80%;
    height: 50px;
    margin: 30px;
    border: 1px solid #707070;
}

.btn {
    width: 30%;
    height: 50px;
    margin: auto 30%;
    color: #fff;
    background-color: #107ACC;
    border: none;
}
.btn:hover {
    background-color: #103FCC;
}
.btn:active {
    transform: scale(0.95);
  }

/* Tabletas */
@media (min-width: 768px) and (max-width: 1024px) {
    .mainContent {
        width: 50%;
    }
}

/* Móviles Medianos */
@media (min-width: 481px) and (max-width: 767px) {
    .mainContent {
        width: 80%;
    }
}


/* Móviles Pequeños */
@media (max-width: 480px) {
    .mainContent {
        width: 96%;
    }
}