.mainContent {
    width: 50%;
    height: 75%;
    background: #fff;
    margin: auto;
    margin-top: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    border-radius: 30px;
    transition: all 500ms ease;
}
.data {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.dataItem {
    width: 80%;
    height: 8%;
    border-bottom: 1px solid #848484;
    color:#848484;
    display: flex;
    align-items: center;
}

.info {
    display: block;
    font-size: 20px;
    font-weight:400;
    margin: 20px;
}
.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
}
.formContent{
    width: 40%;
    margin: 30px;
}
.label {
    margin: auto 30px;
}

.input {
    width: 80%;
    height: 50px;
    margin: 10px;
    border: 1px solid #707070;
}

.btnContent {
    width: 80%;
    margin: 20px 10%;
    display: flex;
    justify-content: space-around;
}
.checkboxContainer {
    display: flex;
    flex-direction:row;
    justify-content: center;
    margin-bottom: 2px;
}

.btn {
    width: 40%;
    height: 50px;
    color: #fff;
    background-color: #107ACC;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
}
.btn:hover {
    background-color: #103FCC;
}
.btn:active {
    transform: scale(0.95);
  }