.mainContent {
    width: 80%;
    height: 70%;
    background: #fff;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    border-radius: 30px;
}

.data {
    width: 90%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #848484;
}
.data_payments {
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    overflow-y: scroll;
    border-top: 1px solid #848484;
    border-bottom: 1px solid #848484;
}
.data_payments::-webkit-scrollbar {
     
}
.dataItem {
    width: 90%;
    border-bottom: 1px solid #848484;
    color:#848484;
    display: flex;
    align-items: center;
}

.info {
    display: block;
    font-size: 15px;
    font-weight:400;
    margin: 20px;
}

.label {
    font-size: 13px;
}

.menu{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btnContent {
    width: 80%;
    height: 90%;
    padding: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.btn {
    width: 40%;
    height: 70px;
    margin: 10px 0;
    background: #107ACC;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    
}

.btn:hover {
    background: #103FCC;
}

.linksContent {
    display: none;
    height: 40%;
    width: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.links {
    text-align: center;
    margin: 10px;
    color: #107ACC;
    text-decoration: none;
}

/* Pantallas Pequeñas de Laptop */ 
@media (min-width: 1025px) and (max-width: 1600px) { 
    .data_payments {
        width: 95%;
        margin-top: 10px;
        
    }
    .info {
        width: 30%;
        font-size: 13px;
    }
}

/* Tabletas */
@media (min-width: 768px) and (max-width: 1024px) {
    .mainContent {
        width: 90%;
    }
    .dataItem {
        width: 90%;
        flex-wrap: wrap;
        
    }
    .info {
        width: 20%;
    }
    .label {
        width: 20%;
    }
}

/* Móviles Medianos */
@media (min-width: 481px) and (max-width: 767px) {
    .mainContent {
        width: 90%;
        height: 700px;
        margin: 120px auto;
    }
    .dataItem {
        width: 90%;
        flex-wrap: wrap;
        
    }
    .info {
        width: 40%;
    }
    .label {
        width: 40%;
    }
}


/* Móviles Pequeños */
@media (max-width: 480px) {
    .mainContent {
        width: 90%;
        height: 500px;
        margin: 120px auto;
    }
    .dataItem {
        width: 90%;
        flex-wrap: wrap;
        
    }
    .info {
        width: 40%;
    }
    .label {
        width: 40%;
    }
}