.contentMain {
    width: 100%;
    height: 50px;
    background: #BEBEBE;
    color: white;
    font-size: 14px;
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.contentMain .contact{
    width: 20%;
    display: flex;
    justify-content: space-between;
}
/* Pantallas Pequeñas de Laptop */ 
@media (min-width: 1025px) and (max-width: 1600px) { 
    .contentMain .contact { 
        width: 40%; 
    }
}

/* Tabletas */
@media (min-width: 768px) and (max-width: 1024px) {
    .contentMain .contact { 
        width: 50%; 
    }
}

/* Móviles Medianos */
@media (min-width: 481px) and (max-width: 767px) {
    .contentMain {
        font-size: 10px;
    }
    .contentMain .contact { 
        width: 50%; 
    }
}


/* Móviles Pequeños */
@media (max-width: 480px) {
    .contentMain {
        font-size: 15px;
    }
    .contentMain .contact { 
        display: none;
    }
}